<template>
  <div class="container">
    <div class="citizen-service">
      <div class="citizen-item">
        <h2 class="citizen-title">Hurmatli fuqaro, murojaatingiz uchun raxmat!</h2>
        <p class="citizen-text">Sizning murojaatingiz O‘zbekiston Respublikasi Kambag'allikni qisqartirish va bandlik vazirligida ro‘yxatga olindi, murojaatingiz natijasi haqida sizga ma’lum qilinadi. Murojaat holatini tekshirish uchun</p>
        <h5>ID-raqam:&nbsp; <span> &nbsp;{{ form.id }}</span></h5>
        <h5>Kod:&nbsp; <span> &nbsp;{{ form.status_code }}</span></h5>
        <p class="citizeSave">(ID raqamingiz va tekshirish uchun kodni saqlab qo‘yishingizni so‘raymiz)</p>
        <a href="/" class="btn homeBtn"><i class="lni-arrow-left" />&nbsp; Bosh sahifaga qaytish</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style>

</style>
