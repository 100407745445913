<template>
  <application-leave v-if="form.step == 1" :form="form" />
  <div v-if="form.step == 2" class="container application-form bg-white ">
    <el-card>
      <div class="mb-5">
        <el-form
          ref="formRef"
          :model="form"
          label-width="120px"
          label-position="top"
          status-icon
          :rules="rules"
          class="top-label-custom"
        >
          <h3 class="section-title">{{ $t("Shaxsiy ma'lumotlar") }}</h3>
          <div v-loading="loading">
            <el-row>
              <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
                <el-form-item :label="$t('Shaxs toifasi')">
                  <el-radio-group v-model="form.person_type" size="large">
                    <el-radio-button :label="1">Jismoniy shaxs</el-radio-button>
                    <el-radio-button :label="2">Yuridik shaxs</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
                <el-form-item :label="$t('Pasport seriya va raqamingiz')" prop="citizen_passport">
                  <el-input ref="pasportForm" v-model="form.citizen_passport" v-maska="'AA#######'" size="large" placeholder="AA0000000" />
                </el-form-item>
              </el-col>
              <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
                <el-form-item :label="$t('JShShIRni kiriting')" prop="person_pin">
                  <el-input ref="bDateForm" v-model="form.person_pin" v-maska="'##############'" size="large" placeholder="00000000000000">
                    <!-- <template #append @click="infoDialogVisiblePin = true">
                      <el-tooltip class="item" effect="light" content="JShShIR nima?" placement="top-start">
                        <el-button icon="el-icon-info" circle type="primary" size="mini" />
                      </el-tooltip>
                    </template> --> 
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
                <el-form-item :label="$t('Bog‘lanish uchun qo‘shimcha telefon')">
                  <el-input v-model="form.additional_phone" v-maska="'+998#########'" size="large" placeholder="+998900000000" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="16" :xs="24" :sm="12" :lg="8" :xl="16">
                <el-form-item :label="$t('F.I.O.')" prop="citizen_name">
                  <el-input v-model="form.citizen_name" disabled size="large" placeholder="FIO" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <!--  -->
          <template v-if="form.person_type == 1">
            <h3 class="section-title">{{ $t('Yashash manzilingiz') }}</h3>
            <el-row>
              <el-col :span="6" :xs="24" :sm="12" :lg="8" :xl="6">
                <el-form-item :label="$t('Hudud')" prop="rev_citizen_region_id">
                  <el-select v-model="form.rev_citizen_region_id" size="large" @change="getDistrict(form.rev_citizen_region_id, 1)">
                    <el-option
                      v-for="item in regions"
                      :key="item.id" 
                      :label="item.name_cyrl"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" :xs="24" :sm="12" :lg="8" :xl="6">
                <el-form-item :label="$t('Tuman(shahar)')" prop="rev_citizen_city_id">
                  <el-select v-model="form.rev_citizen_city_id" size="large">
                    <el-option
                      v-for="item in districts"
                      :key="item.id"
                      :label="item.name_cyrl"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" :xs="24" :sm="12" :lg="8" :xl="12">
                <el-form-item :label="$t('Mfy/ko‘cha/xonadan')">
                  <el-input v-model="form.citizen_address" size="large" />
                </el-form-item>
              </el-col>
            </el-row>          
          </template>
          <!--  -->
          <h3 class="section-title">{{ $t('Murojaat') }}</h3>
          <el-row>           
            <el-col :span="12" :xs="24" :sm="12" :lg="8" :xl="12">
              <el-form-item :label="$t('Qaysi tashkilot tomonidan mehnat huquqingiz buzilyapti?')" prop="company_name">
                <el-input v-model="form.company_name" size="large" />
              </el-form-item>
            </el-col>
            <el-col :span="12" :xs="24" :sm="12" :lg="8" :xl="12">
              <el-form-item :label="$t('Murojaat masalalari')" prop="rev_categories_str">
                <el-select v-model="form.rev_categories_str" size="large" multiple>
                  <el-option
                    v-for="item in categories"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!--  -->
          <h3 class="section-title">{{ $t('Korxona manzili') }}</h3>
          <el-row v-if="form.person_type == 2">
            <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
              <el-form-item :label="$t('Korxona INNsi')" prop="inn">
                <el-input v-model="form.inn" v-maska="'#########'" size="large" placeholder="000000000" />
              </el-form-item>
            </el-col>
            <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
              <el-form-item :label="$t('THSHT')" prop="tshid">
                <el-input v-model="form.tshid"  size="large" placeholder="000000000" />
              </el-form-item>
            </el-col>
            <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
              <el-form-item :label="$t('IFUT')" prop="ifut">
                <el-input v-model="form.ifut"  size="large" placeholder="000000000" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
              <el-form-item :label="$t('Hudud')" prop="region_id">
                <el-select v-model="form.region_id" size="large" @change="getDistrict(form.region_id,2)">
                  <el-option
                    v-for="item in regions"
                    :key="item.id"
                    :label="item.name_cyrl"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
              <el-form-item :label="$t('Tuman(shahar)')" prop="city_id">
                <el-select v-model="form.city_id" size="large">
                  <el-option
                    v-for="item in districts"
                    :key="item.id"
                    :label="item.name_cyrl"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
              <el-form-item :label="$t('Mfy/ko‘cha/xonadan')">
                <el-input v-model="form.rev_citizen_company_address" size="large" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
              <el-form-item :label="$t('Bog‘lanish uchun qo‘shimcha telefon')" prop="company_phone">
                <el-input v-model="form.company_phone" size="large" />
              </el-form-item>
            </el-col>
            <el-col :span="8" :xs="24" :sm="12" :lg="8" :xl="8">
              <el-form-item :label="form.person_type == 2 ? $t('Korxona direktori') : $t('Korxona vakili F.I.O.si')" prop="company_representative">
                <el-input v-model="form.company_representative" size="large" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col v-if="form.person_type == 1" :span="24" :xs="24" :sm="12" :lg="8" :xl="24">
              <el-form-item :label="$t('Murojatga qo‘shimcha ma’lumot (rasmlar, xujjat pdf)')">
                <el-upload
                  ref="upload"
                  class="upload-demo"
                  :action="mediaUrl"
                  :data="{phone: form.phone}"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  list-type="picture"
                >
                  <el-button type="primary">Yuklash uchun bosing</el-button>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col v-else :span="24" :xs="24" :sm="12" :lg="8" :xl="24">
              <el-form-item :label="$t('Murojatga qo‘shimcha ma’lumot (rasmlar, xujjat pdf)')" prop="fileList">
                <el-upload
                  ref="upload"
                  class="upload-demo"
                  :action="mediaUrl"
                  :data="{phone: form.phone}"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  :on-success="handleAvatarSuccess"
                  list-type="picture"
                >
                  <el-button type="primary">Yuklash uchun bosing</el-button>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" :xs="24" :sm="12" :lg="8" :xl="24">
              <el-form-item prop="message" :label="$t('Murojat matni: qaysi mehnat huquqlaringiz buzilganligi va qanday amaliy yordam ko‘rsatish kerakligini eting')">
                <el-input
                  v-model="form.message"
                  :autosize="{ minRows: 4, maxRows: 7 }"
                  type="textarea"
                  placeholder="Murojat matni"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="form.person_type == 1">
            <el-col :span="24" :xs="24" :sm="12" :lg="8" :xl="24">
              <el-form-item>
                <el-checkbox
                  v-model="form.i_am_attendance"
                  :label="$t('Mening ishtirokimda o‘rganish')"
                  size="large"
                  border
                />
                <!-- </el-select> -->
                
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <a            
          class="btn saveButton float-right"
          @click="save()"
        >Saqlash</a>
      </div>
    </el-card>
  </div>
  <div v-if="form.step == 3">
    <complate-message :form="code_complete" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ApplicationLeave from './application-leave.vue'
import ComplateMessage from '../message/citizen-service.vue'
import { ElMessage, ElNotification } from 'element-plus'
import axios from 'axios'
export default {

  name: 'ApplicationCreate',
  components: { ApplicationLeave, ComplateMessage },
  data() {
    return {
      regions: [],
      districts: [],
      active_positions: [],
      imageUrl: '',
      loading: false,
      saveLoading: false,
      validated: false,
      fileList: [],
      form: {
        fileList: [],
        person_type: 1,
        step: 1,
        phone: null,
        citizen_passport: null,
        person_pin: null,
        citizen_name: null,
        birth_date: null,
        additional_phone: null,
        // 
        rev_citizen_region_id: null,
        rev_citizen_city_id: null,
        rev_citizen_company_address: null,
        is_address_now: false,
        active_positions: null,
        // 
        inn: null,
        temporarily_citizen_region_id: null,
        temporarily_citizen_city_id: null,
        temporarily_citizen_address: null,
        region_id: null,
        company_phone: null,
        message: null,
        city_id: null,
        citizen_address: null,
        company_name: null,
        company_representative: null,
        is_fio_anonym: null,
        i_am_attendance: null,
        rev_categories_str: null,
        name: null
      },
      code_complete: {
        id: null,
        status_code: null
      },
      categories: [
        { id: 1, name: 'Majburiy mahnat', disabled: false },
        { id: 2, name: 'Ish haqi masalasi', disabled: false },
        { id: 3, name: 'Noqonuniy ishdan bushatish', disabled: false },
        { id: 4, name: 'Ishga qabul qilmaslik', disabled: false },
        { id: 5, name: 'Ishlash sharoitlari yomon', disabled: false },
        { id: 6, name: 'Boshqa', disabled: false },
        { id: 7, name: 'Tushuntirish olish' }
      ]      
    }
  },
  computed: {
    ...mapGetters({ regionsAxios: 'region/GET_REGIONS' }),
    mediaUrl() {
      return 'https://dmi.mehnat.uz/api/v1/uploadFile/'
    },
    rules() {
      return {
        citizen_passport: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        person_pin: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        citizen_name: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        rev_citizen_region_id: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        rev_citizen_city_id: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        company_name: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        rev_categories_str: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        region_id: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        city_id: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        company_phone: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        company_representative: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        inn: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        message: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }],
        fileList: [{ required: true, message: this.$t("Maydonni to'ldiring"), trigger: 'change' }]
      }
    },
    isBirthDateFull() {
      return this.form.person_pin && this.form.person_pin.length === 14
    },
    isPassportFull() {
      return this.form.citizen_passport.length === 9
    },
    isInnFull() {
      return this.form.inn && this.form.inn.length === 9
    }
  },
  watch: {
    'form.citizen_passport'(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (this.isPassportFull) {
          if (this.isBirthDateFull) {
            this.getPassportInfo()
          } else {
            this.$refs.bDateForm.focus()
          }
        }
      }
    },
    'form.person_pin'(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (this.isBirthDateFull) {
          if (this.isPassportFull) {
            this.getPassportInfo()
          } else {
            this.$refs.pasportForm.focus()
          }
        }
      }
    },
    isInnFull(newVal, oldVal) {
      if (newVal) {
        this.getCompInfo(this.form.inn)
      }
    }
  },
  mounted() {
    this.fetchRegions()
  },
  methods: {
    ...mapActions({ fetchRegionsAxios: 'region/regions', getCompanyInfo: 'appointment/getCompanyInfo' }),
    validateForm() {
      this.$refs['formRef'].validate((valid) => {
        this.validated = valid
      })
      return this.validated
    },
    getCompInfo(inn) {
      this.getCompanyInfo({ inn: inn }).then(res => {
        if (res.success) {
          this.form.region_id = res.data.details.region_id
          this.getDistrict(this.form.region_id)
          this.form.city_id = res.data.details.city_id
          this.form.rev_citizen_company_address = res.data.details.ADDR
          this.form.company_name = res.data.details.ACRON_UZ
          this.form.company_phone = res.data.details.PHONE
          this.form.company_representative = res.data.details.HEAD_NM
        }
      })
    },
    fetchRegions() {
      fetch('https://dmi.mehnat.uz/api/v1/citizen/getregions')
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          this.regions = data.data
        })
    },
    getDistrict(region_id, addres) {
      if (addres === 1) {
        this.form.rev_citizen_city_id = null
      } else if (addres === 2) {
        this.form.city_id = null        
      }
      fetch(`https://dmi.mehnat.uz/api/v1/citizen/getcities/${region_id}`)
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          this.districts = data.data
        })
    },
    getPassportInfo() {
      const data = {
        passport: this.form.citizen_passport,
        pin: this.form.person_pin
      }
      this.loading = true
      axios.post(`https://dmi.mehnat.uz/api/v1/appeals/check-passport-info`, data)
        .then(res => {
          if (res.data.success) {
            this.form.citizen_name = [res.data.details.sname, res.data.details.fname, res.data.details.lname].join(' ')
            this.form.citizen_address = res.data.details.address
            this.form.birth_date = res.data.details.date_birth
            if (res.data.details.active_positions && res.data.details.active_positions.length) {
              this.form.region_id = res.data.details.active_positions[0].region_id
              this.getDistrict(this.form.region_id)
              this.form.city_id = res.data.details.active_positions[0].city_id
              this.form.rev_citizen_company_address = res.data.details.active_positions[0].street
              this.form.company_name = res.data.details.active_positions[0].company_profile_name
              this.form.company_phone = res.data.details.active_positions[0].company_phones
              this.form.company_representative = res.data.details.active_positions[0].director
            }
          } else {
            this.clearPassportInfo()
            ElMessage({
              type: 'error',
              message: res.data.message || `Fuqaro topilmadi`
            })
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          ElMessage({
            type: 'error',
            message: `Fuqaro topilmadi`
          })
          this.clearPassportInfo()
          this.loading = false
        })
        .finally(() => {          
          this.loading = false
        })
    },
    // save
    save() {
      if (this.validateForm()) {
        this.saveLoading = true
        const dates = { ...this.form }
        dates.rev_categories_str = dates.rev_categories_str.join()
        axios.post(`https://dmi.mehnat.uz/api/v1/appeals/saveinfo`, dates)
          .then(res => {
            if (res.data.success) {
              ElNotification({
                title: 'Yuborildi',
                message: 'Muvaffaqiyatli yuborildi',
                type: 'success'
              })
              this.code_complete.id = res.data.data.id
              this.code_complete.status_code = res.data.data.status_code
              this.form.step = 3
            } else {
              ElMessage({
                type: 'error',
                message: `Ma'lumot to'ldirishda xatolik`
              })
            }
            this.saveLoading = false
          })
          .catch(err => {
            console.log(err)
            ElMessage({
              type: 'error',
              message: `Ma'lumot to'ldirishda xatolik`
            })
            this.saveLoading = false
          })
          .finally(() => {          
            this.saveLoading = false
          })
      } else {
        ElNotification({
          title: 'Xatolik',
          message: "Maydonlarni to'ldiring",
          type: 'error'
        })
      }
    },
    clearPassportInfo() {
      this.form.citizen_name = null
      this.form.citizen_address = null
      this.form.birth_date = null
      this.form.region_id = null   
      this.form.city_id = null
      this.form.rev_citizen_company_address = null
      this.form.company_name = null
      this.form.company_phone = null
      this.form.company_representative = null
    },
    // upload
    handleRemove(file, fileList) {
      this.form.fileList = []
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    handleAvatarSuccess(res, file) {
      this.form.fileList = URL.createObjectURL(file.raw)
    },
    beforeUpload() {
      if (!this.form.phone) {
        ElMessage({
          type: 'error',
          message: `Fayl yuklash uchun avval telefonni to'ldiring`
        })
      }
    }
  }

}
</script>

<style>

</style>
