<template>
  <div class="container">
    <div class="application">
      <div class="application-item">
        <div class="application-list">
          <h2 class="application-title">Davlat mehnat inspeksiyasi saytiga xush kelibsiz.</h2>
          <p class="application-text">Agar mehnat huquqlaringiz buzilgan bo‘lsa, ushbu sayt orqali inspeksiyaga
            murojaat yuborishingiz mumkin.</p>
          <p v-if="phone_confirmed" class="application-text"> {{ phone }} telefon raqamiga yuborilgan tasdiqlash kodini kiriting. <br>
            <span v-if="startCountDown">
              {{ getTime }}
            </span>
            <span v-else>
              <b class="text-primary btn" @click="nextStep(true)">Qayta yuborish</b>
            </span>
          </p>          
          <div class="application-link">
            <template v-if="!phone_confirmed">
              <p>Shaxsiy mobil raqamingizni kiriting</p>
              <input v-model="phone" v-maska="'+############'" placeholder="+998900000000" type="phone">
            </template>
            <template v-if="phone_confirmed">
              <p>Tasdiqlash kodi</p>
              <input v-model="code" v-maska="'#####'" type="number">
            </template> 
          </div>
          <div class="button-list">
            <a href="/" class="back-btn"><i class="lni-arrow-left" />&nbsp; Oqaga qaytish</a>
            <a class="next-btn" @click="nextStep(false)">Keyingi</a>
          </div>
        </div>
        <div class="application-list">
          <img class="applicationImg" src="@/assets/images/img/holatni-tekshirish.png" width="500px" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import global from '@/mixins/global.js'
import { ElMessage } from 'element-plus'
import axios from 'axios'
export default {
  mixins: [global],  
  props: {
    form: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      phone: null,
      code: null,
      loading: false,
      phone_confirmed: false,
      countdown: null,
      startCountDown: false
    }
  },
  computed: {
    getTime() {
      let m = Math.trunc(this.countdown / 60)
      let s = this.countdown % 60
      if (m <= 9) m = '0' + m
      if (s <= 9) s = '0' + s
      return m + ':' + s // parseTime(this.countdown, '{m}:{s}')
    }
  },
  watch: {
    'phone'(newVal, oldVal) {
      // eslint-disable-next-line vue/no-mutating-props
      this.form.phone = newVal
    },
    'code'(newVal, oldVal) {
      if (newVal.length >= 5) {
        this.confirmateCode()
      }
    }
  },
  methods: {
    nextStep(resend = false) {
      if (!this.phone || this.phone.length < 13) {
        ElMessage({
          type: 'error',
          message: 'Telefon raqamni kiriting'
        })
      } else if (!this.phone_confirmed || resend) {
        this.loading = true
        axios.post(`https://dmi.mehnat.uz/api/v1/appeals/send-sms`, { phone: this.form.phone })
          .then(() => {
            this.countdown = 120
            this.startCountDown = true
            this.countDownTimer()
            this.phone_confirmed = true
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        this.confirmateCode()
      }
    },
    countDownTimer() {
      if (this.countdown > 0) {
        this.timer = setTimeout(() => {
          this.countdown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.startCountDown = false
      }
    },
    confirmateCode() {
      axios.post(`https://dmi.mehnat.uz/api/v1/appeals/confirm-sms`, { phone: this.form.phone, code: this.code })
        .then(() => {
          // eslint-disable-next-line vue/no-mutating-props
          this.form.step = 2
        })
        .catch(() => {
          ElMessage({
            type: 'error',
            message: `Kod to'g'ri kelmadi`
          })
        })
    }
  }
}
</script>

<style>

</style>
